<template>
  <app-select
    v-bind="$attrs"
    hide-details="auto"
    v-on="$listeners"
    :dense="dense"
    :items="items"
    label="Classificação do produto"
  />
</template>

<script>
export default {
  props: {
    dense: {
      default: true,
    },
  },

  data: () => ({
    items: [
      { text: "00 - Mercadoria para Revenda", value: "00" },
      // { text : '01 - Matéria-Prima', value : '01' },
      // { text : '02 - Embalagem', value : '02' },
      // { text : '03 - Produto em Processo', value : '03' },
      // { text : '04 - Produto Acabado', value : '04' },
      // { text : '05 - Subproduto', value : '05' },
      // { text : '06 - Produto Intermediário', value : '06' },
      { text: "07 - Material de Uso e Consumo", value: "07" },
      { text: "08 - Ativo Imobilizado", value: "08" },
      // { text : '09 - Serviços', value : '09' },
      // { text : '10 - Outros insumos', value : '10' },
      // { text : '99 - Outras', value : '99' },
    ],
  }),
};
</script>
